// Since these sections are all universal, make them constants for the rest of the code
const HEADERCONTAINER = document.getElementById("header-container");
const LOGINMENU = document.getElementById("nav-menu-login");
const LOGINOPEN = document.getElementById("login-open");
const SEARCHMENU = document.getElementById("nav-menu-search");
const SEARCHOPEN = document.getElementById("search-open");
const STANDARDHEADER = document.getElementById("header-standard");
const HEADERS = document.getElementById("header-container");
const URLHASH = window.location.hash;


// Login Menu Open and Close
function openLoginNav() {
    if(!isVisible(LOGINMENU)) {
        vSlideDown(LOGINMENU, 500, "block", function() {
            focusFirst(LOGINMENU);
        });
    }
    // ESC key behavior
    if(!LOGINMENU.classList.contains("esc-bound")) {
        LOGINMENU.addEventListener("keyup", function(event) {
            if((event.key === "Escape" || event.keyCode === 27) && isVisible(LOGINMENU)) {
                closeLoginNav();
            }
        });
        LOGINMENU.classList.add("esc-bound");
    }
}
function closeLoginNav() {
    if(isVisible(LOGINMENU)) {
        vSlideUp(LOGINMENU, 500, function() {
            LOGINOPEN.focus();
        });
    }
}
document.addEventListener("click", function(event) {
    var openLoginButton = getParentElementWithClass(event.target, "js-login-open");
    if(openLoginButton !== null) {
        openLoginNav();
    }
});
document.addEventListener("click", function(event) {
    var closeLoginButton = getParentElementWithClass(event.target, "js-login-close");
    if(closeLoginButton !== null) {
        closeLoginNav();
    }
});


// Search Menu Open and Close
function openSearchNav(elem) {
    if(!isVisible(SEARCHMENU)) {
        vSlideDown(SEARCHMENU, 500, "block", function() {
            focusFirst(SEARCHMENU);
        });
    }
    // ESC key behavior
    if(!SEARCHMENU.classList.contains("esc-bound")) {
        SEARCHMENU.addEventListener("keyup", function(event) {
            if((event.key === "Escape" || event.keyCode === 27) && isVisible(SEARCHMENU)) {
                closeSearchNav(document.querySelector(".js-search-close"));
            }
        });
        SEARCHMENU.classList.add("esc-bound");
    }
}
function closeSearchNav(elem) {
    if(isVisible(SEARCHMENU)) {
        vSlideUp(SEARCHMENU, 500, function() {
            document.querySelector(".js-search-open").focus();
        });
        SEARCHOPEN.focus();
    }
}
document.addEventListener("click", function(event) {
    var openSearchNavButton = getParentElementWithClass(event.target, "js-search-open");
    if(openSearchNavButton !== null) {
        openSearchNav(openSearchNavButton);
    }
});
document.addEventListener("click", function(event) {
    var closeSearchNavButton = getParentElementWithClass(event.target, "js-search-close");
    if(closeSearchNavButton !== null) {
        closeSearchNav(closeSearchNavButton);
    }
});


/*** Dropdown Menus ***/
// Click events for the mobile navigation.
document.addEventListener("click", function(event) {
    let dropdownButton = getParentElementWithClass(event.target, "js-dropdown-button");
    if(dropdownButton !== null && window.innerWidth < TABLETBREAK) {
        let dropdownRoot = event.target.parentElement;
        if(dropdownRoot.classList.contains('active')) {
            dropdownRoot.classList.remove('active');
            let dropdown = dropdownRoot.getElementsByClassName('js-dropdown-menu')[0];
            if(dropdown !== undefined && dropdown !== null) {
                dropdown.setAttribute('aria-expanded', 'false');
                vSlideUp(dropdown);
            }
        } else {
            let alreadyOpenMenu = document.querySelector(".js-dropdown-root.active");
            if(alreadyOpenMenu !== null) {
                alreadyOpenMenu.classList.remove('active');
                let dropdown = alreadyOpenMenu.getElementsByClassName('js-dropdown-menu')[0];
                if(dropdown !== undefined && dropdown !== null) {
                    dropdown.setAttribute('aria-expanded', 'false');
                    vSlideUp(dropdown);
                }
            }
            dropdownRoot.classList.add('active');
            var dropdown = event.target.parentElement.getElementsByClassName('js-dropdown-menu')[0];
            if(dropdown !== undefined && dropdown !== null) {
                dropdown.setAttribute('aria-expanded', 'true');
                vSlideDown(dropdown);
            }
        }
    }
});

// get top-level items from a dropdown menu
var menuItems = document.querySelectorAll('#header-standard .js-dropdown-root');
// set actions for mouse over and mouse leave
var hoverIntent = new SV.HoverIntent(menuItems, {
    // required parameters
    onEnter: function(targetItem) {
        if(window.innerWidth >= TABLETBREAK) {
            targetItem.classList.add('active');
            var dropdown = targetItem.getElementsByClassName('js-dropdown-menu')[0];
            if(dropdown !== undefined && dropdown !== null) {
                dropdown.setAttribute('aria-expanded', 'true');
                vSlideDown(dropdown);
            }
        }
    },
    onExit: function(targetItem) {
        if(window.innerWidth >= TABLETBREAK) {
            targetItem.classList.remove('active');
            var dropdown = targetItem.getElementsByClassName('js-dropdown-menu')[0];
            if(dropdown !== undefined && dropdown !== null) {
                dropdown.setAttribute('aria-expanded', 'false');
                vSlideUp(dropdown);
            }
        }
    },
    // default options
    exitDelay: 300,
    interval: 100,
    sensitivity: 7,
});

window.addEventListener("resize", function(event) {
    let dropdownMenus = document.querySelectorAll(".js-dropdown-menu");
    for(let i = 0; i < dropdownMenus.length; i++) {
        if(isVisible(dropdownMenus[i]) && window.innerWidth >= TABLETBREAK) {
            dropdownMenus[i].parentElement.classList.remove('active');
            dropdownMenus[i].setAttribute('aria-expanded', 'false');
            vHide(dropdownMenus[i]);
        }
    }
});


/* Show / Hide Navigation */
var lastScrollTop = 0;
// element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
window.addEventListener("scroll", function() { // or window.addEventListener("scroll"....
   var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
   if (st > lastScrollTop && HEADERCONTAINER.classList.contains("scroll-show")) {
      HEADERCONTAINER.classList.remove("scroll-show");
  } else if(st < lastScrollTop && !HEADERCONTAINER.classList.contains("scroll-show")) {
      HEADERCONTAINER.classList.add("scroll-show");
   }
   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}, false);


// The Jump Navigation Ribbon
document.addEventListener('DOMContentLoaded', function () {
    let jumpLinks = document.querySelectorAll("[data-jumplink]");
    if (jumpLinks != null && jumpLinks.length) {
        let mainElemenet = document.querySelector("main");
        let ribbonElement = document.createElement("div");
        ribbonElement.classList.add("block-jump-ribbon");
        let ribbonMarkup = "<ul>";
        jumpLinks.forEach(function (lnk) {
            ribbonMarkup += '<li><button class="js-jump-lnk" type="button">' + lnk.getAttribute("data-jumplink").replace(/_/g, " ") + '<span class="indicator"></span></button></li>';
        });
        ribbonMarkup += "</ul>";
        ribbonElement.innerHTML = ribbonMarkup;
        mainElemenet.prepend(ribbonElement);
    }
}, false);


// Alerts
document.addEventListener("click", function(event) {
    if(event.target.classList.contains("js-alert-close")) {
        let alertID = parseInt(event.target.getAttribute("data-alert-id"));
        if(!isNaN(alertID) && alertID > -1) {
            setCookieValue("siteAlertDismissed_" + alertID, "true", 30);
            vHide(event.target.parentElement);
        }
    }
});


// Jump link click event listener
document.addEventListener("click", function(event) {
    if (event.target.matches(".js-jump-lnk")) {
        var btn = document.querySelectorAll("[data-jumplink='" + event.target.innerText.replace(/\s/g, "_") + "']");
        if (btn != null && btn.length > 0) {
            btn[0].scrollIntoView();
        }
    }
});


// Product Tabs / Accordions
let tabBlocks = document.querySelectorAll(".block-products-know-more-tabs");
if(tabBlocks.length > 0) {
    function resetProductTab(containerElement, index) {
        let tabButtons = containerElement.querySelectorAll(".js-tab-button");
        let tabContents = containerElement.querySelectorAll(".js-accordion-content");
        let tabAccordionButtons = containerElement.querySelectorAll(".js-tabs-accordion-button");
        vHide(tabContents[index]);
        tabButtons[index].classList.remove("active");
        tabButtons[index].parentElement.classList.remove("active");
        tabAccordionButtons[index].classList.remove("active");
        if(index > 0) {
            let preActiveTabIndex = parseInt(index) - 1;
            tabButtons[preActiveTabIndex].parentElement.classList.remove("pre-active");
        }
    }

    for(let i = 0; i < tabBlocks.length; i++) {
        // Create the tabs >:(
        let tabSection = tabBlocks[i].querySelector(".js-tab-area");
        let tabAccordionButtons = tabBlocks[i].querySelectorAll(".js-tabs-accordion-button");
        for(let j = 0; j < tabAccordionButtons.length; j++) {
            let activeClass = "";
            if(j == 0) {
                activeClass = " active";
            }
            let theTitle = "";
            if(tabAccordionButtons[j].querySelector(".js-tab-title").innerText != null) {
                theTitle = tabAccordionButtons[j].querySelector(".js-tab-title").innerText;
            }
            let theIntro = "";
            if(tabAccordionButtons[j].querySelector(".js-tab-intro").innerText != null) {
                theIntro = "<div class=\"prod-intro\">" + tabAccordionButtons[j].querySelector(".js-tab-intro").innerText + "</div>";
            }
            let tabElement = document.createElement('li');
            if(j == 0) {
                tabElement.classList.add("active");
            }
            tabElement.innerHTML = "<button class=\"tab-button js-tab-button" + activeClass + "\" type=\"button\" data-pos=\"" + j + "\"><h3 class=\"f-h4\">" + theTitle + "</h3>" + theIntro + "</button></li>";
            tabSection.append(tabElement);
        }

        let tabButtons = tabBlocks[i].querySelectorAll(".js-tab-button");
        let tabContents = tabBlocks[i].querySelectorAll(".js-accordion-content");
        let tabCloseButtons = tabBlocks[i].querySelectorAll(".js-accordion-close");

        // Add index to all of these
        for(let j = 0; j < tabButtons.length; j++) {
            tabContents[j].setAttribute("data-pos", j);
            tabAccordionButtons[j].setAttribute("data-pos", j);
            tabCloseButtons[j].setAttribute("data-pos", j);
            if(j == 0) {
                tabAccordionButtons[j].classList.add("active");
            }
            if (j > 0) {
                vHide(tabContents[j]);
            }
        }

        // The tab buttons
        for(let j = 0; j < tabButtons.length; j++) {
            tabButtons[j].addEventListener("click", function(evt) {
                let tabContentIsVisible = false;
                for(let k = 0; k < tabContents.length; k++) {
                    if(isVisible(tabContents[k])) {
                        tabContentIsVisible = true;
                    }
                }

                for(let k = 0; k < tabContents.length; k++) {
                    if(k != evt.target.getAttribute("data-pos")) {
                        resetProductTab(tabBlocks[i], k);
                    }
                }
                evt.target.parentElement.classList.toggle("active");
                if(parseInt(evt.target.getAttribute("data-pos")) > 0) {
                    evt.target.parentElement.previousSibling.classList.add("pre-active");
                }
                evt.target.classList.toggle("active");
                tabAccordionButtons[evt.target.getAttribute("data-pos")].classList.toggle("active");

                let activeTabs = tabBlocks[i].querySelectorAll(".js-tab-button.active");

                if (activeTabs.length == 0) {
                    vSlideUp(tabContents[evt.target.getAttribute("data-pos")]);
                    if(evt.target.getAttribute("data-pos") > 0) {
                        tabButtons[parseInt(evt.target.getAttribute("data-pos")) - 1].parentElement.classList.remove("pre-active");
                    }
                } else if (activeTabs.length > 0 && tabContentIsVisible) {
                    vShow(tabContents[evt.target.getAttribute("data-pos")]);
                } else {
                    vSlideDown(tabContents[evt.target.getAttribute("data-pos")]);
                }
            });
        }

        // The accordion buttons on mobile
        for(let j = 0; j < tabAccordionButtons.length; j++) {
            tabAccordionButtons[j].addEventListener("click", function(evt) {
                for(let k = 0; k < tabContents.length; k++) {
                    if(k != evt.target.getAttribute("data-pos")) {
                        resetProductTab(tabBlocks[i], k);
                    }
                }
                evt.target.classList.toggle("active");
                tabButtons[evt.target.getAttribute("data-pos")].classList.toggle("active");
                if(isVisible(tabContents[evt.target.getAttribute("data-pos")])) {
                    vSlideUp(tabContents[evt.target.getAttribute("data-pos")]);
                } else {
                    vSlideDown(tabContents[evt.target.getAttribute("data-pos")]);
                }
            });
        }

        // The close buttons inside of the accordion
        for(let j = 0; j < tabCloseButtons.length; j++) {
            tabCloseButtons[j].addEventListener("click", function(evt) {
                vSlideUp(tabContents[evt.target.getAttribute("data-pos")]);
                tabButtons[evt.target.getAttribute("data-pos")].classList.remove("active");
                tabButtons[evt.target.getAttribute("data-pos")].parentElement.classList.remove("active");
                tabAccordionButtons[evt.target.getAttribute("data-pos")].classList.remove("active");
                if(evt.target.getAttribute("data-pos") > 0) {
                    let preActiveTabIndex = evt.target.getAttribute("data-pos") - 1;
                    tabButtons[preActiveTabIndex].parentElement.classList.remove("pre-active");
                }
            });
        }
    }
}


// Locations Tabs / Accordions
let locationSection = document.querySelectorAll(".mod-location-list");
if(locationSection.length > 0) {
    function resetLocationTab(containerElement, index) {
        let tabContents = containerElement.querySelectorAll(".js-accordion-content");
        let tabButtons = containerElement.querySelectorAll(".js-tab-button");
        let tabAccordionButtons = containerElement.querySelectorAll(".js-tabs-accordion-button");
        vHide(tabContents[index]);
        tabButtons[index].classList.remove("active");
        tabAccordionButtons[index].classList.remove("active");
    }
    for(let i = 0; i < locationSection.length; i++) {
        // Create the tabs >:(
        let tabAccordionButtons = locationSection[i].querySelectorAll(".js-tabs-accordion-button");
        let tabButtons = locationSection[i].querySelectorAll(".js-tab-button");
        let tabContents = locationSection[i].querySelectorAll(".js-accordion-content");

        // Add index to all of these
        for(let j = 0; j < tabButtons.length; j++) {
            tabAccordionButtons[j].setAttribute("data-pos", j);
            tabButtons[j].setAttribute("data-pos", j);
            tabContents[j].setAttribute("data-pos", j);
        }

        // The tab buttons
        for(let j = 0; j < tabButtons.length; j++) {
            tabButtons[j].addEventListener("click", function(evt) {
                for(let k = 0; k < tabContents.length; k++) {
                    if(k != evt.target.getAttribute("data-pos")) {
                        tabContents[k].classList.remove("selected");
                        resetLocationTab(locationSection[i], k);
                    }
                }
                evt.target.classList.add("active");
                tabAccordionButtons[evt.target.getAttribute("data-pos")].classList.add("active");
                if(!isVisible(tabContents[evt.target.getAttribute("data-pos")])) {
                    tabContents[evt.target.getAttribute("data-pos")].classList.add("selected");
                }
            });
        }

        // The accordion buttons on mobile
        for(let j = 0; j < tabAccordionButtons.length; j++) {
            tabAccordionButtons[j].addEventListener("click", function(evt) {
                for(let k = 0; k < tabContents.length; k++) {
                    if(k != evt.target.getAttribute("data-pos")) {
                        resetLocationTab(locationSection[i], k);
                        tabContents[k].classList.remove("selected");
                    }
                }
                evt.target.classList.toggle("active");
                tabContents[evt.target.getAttribute("data-pos")].classList.add("selected");
                tabButtons[evt.target.getAttribute("data-pos")].classList.add("active");
                if(isVisible(tabContents[evt.target.getAttribute("data-pos")])) {
                    vSlideUp(tabContents[evt.target.getAttribute("data-pos")]);
                } else {
                    vSlideDown(tabContents[evt.target.getAttribute("data-pos")]);
                }
            });
        }
    }
}


// Debit Card Selector
let debitCardSelectorSection = document.querySelectorAll(".block-debit-cards");
if(debitCardSelectorSection.length > 0) {
    for(let i = 0; i < debitCardSelectorSection.length; i++) {
        let cardDisplay = debitCardSelectorSection[i].querySelector(".js-card-display");
        let cardButtons = debitCardSelectorSection[i].querySelectorAll(".js-card-button");
        for(let j = 0; j < cardButtons.length; j++) {
            cardButtons[j].addEventListener("click", function(evt) {
                for(let k = 0; k < cardButtons.length; k++) {
                    cardButtons[k].classList.remove("active");
                }
                evt.target.classList.add("active");
                if(evt.target.getAttribute("data-cardsrc") != null && evt.target.getAttribute("data-cardsrc") != "") {
                    cardDisplay.setAttribute("src", evt.target.getAttribute("data-cardsrc"));
                }
            });
        }
        cardButtons[0].classList.add("active");
        cardDisplay.setAttribute("src", cardButtons[0].getAttribute("data-cardsrc"));
    }
}


// Product Detail Blocks with an "optional" accordion
window.addEventListener('DOMContentLoaded', (event) => {
    let detailBlocks = document.querySelectorAll(".block-product-info");
    if(detailBlocks.length > 0) {
        for(let i = 0; i < detailBlocks.length; i++) {
            let blockContentSection = detailBlocks[i].querySelector(".js-prod-content-accordion");
            if(isOverflown(blockContentSection)) {
                let actionsElement = detailBlocks[i].querySelector(".js-prod-actions");
                if(actionsElement == null) {
                    let ctaContainer = detailBlocks[i].querySelector(".prod-actions");
                    let theCTAList = document.createElement('ul');
                    theCTAList.classList.add('js-prod-actions');
                    ctaContainer.append(theCTAList);
                    actionsElement = theCTAList;
                }
                blockContentSection.classList.add("overflown");
                var accordionButtonElement = document.createElement('li');
                accordionButtonElement.innerHTML = "<button class=\"ghost-btn\" type=\"button\"><span class=\"open\">Learn More</span><span class=\"close\">Close</span></button>";
                actionsElement.prepend(accordionButtonElement);
                accordionButtonElement.addEventListener("click", function(evt) {
                    evt.target.classList.toggle("active");
                    blockContentSection.classList.toggle("active");
                });
            }
        }
    }
});


// Correspondent Banking Blocks with an "optional" accordion
window.addEventListener('DOMContentLoaded', (event) => {
    let cbBlocks = document.querySelectorAll(".block-correspondent-banking-item.expandable");
    if(cbBlocks != null && cbBlocks.length > 0) {
        for(let i = 0; i < cbBlocks.length; i++) {
            let accordionButton = cbBlocks[i].querySelector(".js-cb-accordion-button");
            let accordionContent = cbBlocks[i].querySelector(".js-cb-accordion-content");
            accordionButton.addEventListener("click", function(evt) {
                accordionContent.classList.toggle("active");
                accordionButton.classList.toggle("active");
            });
        }
    }
});


/* Compare Tables */
function initComparisonTables() {
    // Find and iterate through all of the uninitialized accordions
    let didInitialize = false;
    let compareTables = document.querySelectorAll('.js-comparison-table:not([data-init="true"])');
    if(compareTables !== null && compareTables.length) {
        didInitialize = true;
        compareTables.forEach(function (table, index) {
            // Identify container to append elements to
            let tableContainer = table.querySelector('.js-table-container');
            // Identify the container
            let tableControlsDots = table.querySelector('.js-table-controls-dots');
            // Identify number of columns
            let tableCols = table.querySelectorAll('.mod-compare-table table tr:first-child th').length;
            // Identify all of the rows we need to iterate through
            let tableRows = table.querySelectorAll('.mod-compare-table tr');
            // Product Titles
            let tableTitles = [];

            // The title field for the account name in the arrow controls
            let tableControlsTitle = table.querySelector(".js-account-title");

            // Previous Button
            let tableNextButton = table.querySelector('.js-prev-account');
            tableNextButton.addEventListener("click", function(e) {
                let theTable = getParentElementWithClass(e.target, 'js-comparison-table');
                tablePrev(theTable, parseInt(theTable.getAttribute('data-current-position')));
            });

            // Next Button
            let tablePrevButton = table.querySelector('.js-next-account');
            tablePrevButton.addEventListener("click", function(e) {
                let theTable = getParentElementWithClass(e.target, 'js-comparison-table');
                tableNext(theTable, parseInt(theTable.getAttribute('data-current-position')));
            });

            // Adding data attributes for the cells
            for(i = 1; i < tableCols; i++) {
                tableTitles.push(tableRows[0].querySelectorAll('th')[i].innerText);
                table.querySelectorAll('.mod-compare-table tr > *:nth-child(' + (i + 1) + ')').forEach(function (cell, index) {
                    cell.setAttribute('data-pos', i);
                });
                if(i == 1) {
                    tableControlsTitle.innerText = tableTitles[i - 1];
                }
            }

            // Carousel Dots
            tableTitles.forEach(function (title, index) {
                // Prev Button
                let carouselDot = document.createElement('li');
                let activeDot = "";
                if(index == 0) {
                    activeDot = " is-active";
                }
                carouselDot.innerHTML = '<button class="carousel-dot js-carousel-dot' + activeDot + '" type="button" data-pos="' + (index + 1) + '"><span class="visuallyhidden">View ' + title + ' Features</span></button>';
                tableControlsDots.append(carouselDot);
                carouselDot.addEventListener("click", function(e) {
                    tableSwitch(getParentElementWithClass(e.target, 'js-comparison-table'), parseInt(e.target.getAttribute('data-pos')));
                });
            });

            // Set the first product as active
            let firstCells = table.querySelectorAll('.mod-compare-table tr > *:nth-child(2)');
            firstCells.forEach(function (cell, index) {
                cell.classList.add('active');
            });

            table.setAttribute('data-init', 'true')
            table.setAttribute('data-current-position', 1);
        });
    }
    return didInitialize;
}
// Initialize the tables
initComparisonTables();
// Click Events
function tablePrev(tableElem, currentIndex) {
    let newIndex = currentIndex - 1;
    let tableCols = tableElem.querySelectorAll('table tr:first-child > *').length;
    if(newIndex == 0) {
        newIndex = tableCols - 1;
    }
    tableSwitch(tableElem, newIndex);
}
function tableNext(tableElem, currentIndex) {
    let newIndex = currentIndex + 1;
    let tableCols = tableElem.querySelectorAll('table tr:first-child > *').length;
    if(newIndex == tableCols) {
        newIndex = 1;
    }
    tableSwitch(tableElem, newIndex);
}
function tableSwitch(tableElem, posNumber) {
    let oldCells = tableElem.querySelectorAll('th, td');
    oldCells.forEach(function (cell, index) {
        cell.classList.remove('active');
    });
    let newCells = tableElem.querySelectorAll('tr > *:nth-child(' + (parseInt(posNumber) + 1) + ')');
    newCells.forEach(function (cell, index) {
        cell.classList.add('active');
    });
    let carouselDots = tableElem.querySelectorAll('.js-carousel-dot');
    carouselDots.forEach(function (dot, index) {
        dot.classList.remove('is-active');
    });
    carouselDots[posNumber - 1].classList.add('is-active');
    tableElem.setAttribute('data-current-position', posNumber);
    tableElem.querySelector('.js-account-title').innerText = tableElem.querySelector('tr:first-child > *:nth-child(' + (parseInt(posNumber) + 1) + ')').innerText;
}


// Video Modal
window.addEventListener('DOMContentLoaded', (event) => {
    let videoButtons = document.querySelectorAll("button[data-video]");
    if(videoButtons.length > 0) {
        for(let i = 0; i < videoButtons.length; i++) {
            if (videoButtons[i].getAttribute("data-video") != "" && !videoButtons[i].parentNode.classList.contains("cta-buttons") && videoButtons[i].parentNode.id != "ctaButtons") {
                videoButtons[i].addEventListener("click", function(clickevent) {
                    clickevent.preventDefault();
                    let videoTitle = "";
                    let videoGUID = "";
                    if (clickevent.target.querySelector("span.visuallyhidden") != null && clickevent.target.querySelector("span.visuallyhidden").length > 0 && clickevent.target.querySelector("span.visuallyhidden").innerText != "") {
                        videoTitle = clickevent.target.querySelector("span.visuallyhidden").innerText;
                    }
                    var vidBuild = "<div class='video-modal-content'><button class='video-modal-close'><span class='visuallyhidden'> close</span></button>";
                    if (clickevent.target.getAttribute("data-video").indexOf("vimeo") != -1) {
                        var vid = clickevent.target.getAttribute("data-video").replace("https://vimeo.com/", "");
                        if (vid.indexOf("/") != -1) {
                            vid = vid.substring(0, vid.indexOf("/"));
                        }
                        vidBuild += "<div style='padding: 56.25% 0 0 0; position: relative;'><iframe src='https://player.vimeo.com/video/" + vid + "?title=0&byline=0&portrait=0' alt='" + videoTitle + "' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe></div><script src='https://player.vimeo.com/api/player.js'></script>";
                    }
                    else if (clickevent.target.getAttribute("data-video").indexOf("youtube") != -1) {
                        var vid = clickevent.target.getAttribute("data-video").replace("https://www.youtube.com/watch?v=", "");
                        if (vid.indexOf("/") != -1) {
                            vid = vid.substring(0, vid.indexOf("/"));
                        }
                        vidBuild += "<div style='padding: 56.25% 0 0 0; position: relative;'><iframe src='https://www.youtube.com/embed/" + vid + "' alt='" + videoTitle + "' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='accelerometer; autoplay; encrypted - media; gyroscope; picture -in -picture; fullscreen' allowfullscreen></iframe></div>";
                    }
                    else if (clickevent.target.getAttribute("data-video").indexOf(".mp4")) {
                        vidBuild += "<video width='100%' controls autoplay><source src='" + clickevent.target.getAttribute("data-video") + "' type='video/mp4'></video>";
                    }
                    vidBuild += "</div>";

                    var vidModal = document.querySelector(".video-modal");
                    if (vidModal !== null) {
                        vidModal.parentNode.removeChild(vidModal);
                    }

                    var div = document.createElement("div");
                    div.classList.add("video-modal");
                    div.innerHTML = vidBuild;
                    document.body.appendChild(div);
                });
            }
        }
    }
});


// Generic click event
document.addEventListener("click", function(e) {
    if (e.target.matches(".video-modal")) {
        e.target.parentNode.removeChild(e.target);
    }
    else if (e.target.matches(".video-modal-content")) {
        e.stopPropagation();
    }
    else if (e.target.matches(".video-modal-close")) {
        e.preventDefault();
        var vidModal = document.querySelector(".video-modal");
        if (vidModal !== null) {
            vidModal.parentNode.removeChild(vidModal);
        }
    }
});

// Cookie bar Logic
// window.addEventListener("DOMContentLoaded", () => {
//     const allCookies = document.cookie;
//     const alertCookie = allCookies.split('; ').find((row) => row.startsWith('siteCookieAlertDismissed_='));
//     if (alertCookie && alertCookie.split('=')[1] == 'true') {
//         document.querySelector('#cookie-policy').style.display = 'none';
//     }
// });
// document.querySelector('.js-cookie-policy-accept').addEventListener('click', () => {
//     setCookieValue("siteCookieAlertDismissed_", "true", 365);
//     document.querySelector('#cookie-policy').style.display = 'none';
// });


let locationFilters = document.querySelector('.loc-filters');
if(locationFilters != null) {
    locationFilters.addEventListener('scroll', () => {
        if (event.target.scrollLeft != 0) {
            document.documentElement.style.setProperty('--beforeElementOpacity', '1');
        }
        else {
            document.documentElement.style.setProperty('--beforeElementOpacity', '0');
        };
        if (event.target.scrollLeft + event.target.offsetWidth == event.target.scrollWidth) {
            document.documentElement.style.setProperty('--afterElementOpacity', '0');
        }
        else {
            document.documentElement.style.setProperty('--afterElementOpacity', '1');
        }
    });
}

//map button logic
let expandMapButton = document.querySelector('.loc-map-area > .expandButton');
if (expandMapButton) {
    expandMapButton.addEventListener('click', function (e) {
        expandMapButton.parentElement.classList.toggle('open');
    })
}

if (document.querySelector('.lastBlock') && document.querySelector('.footer-divider')) {
    document.querySelector('.footer-divider').style.backgroundColor = '#ffe6b3';
}
